import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import queryString from 'query-string';
import printJS from 'print-js';
import { sprintf } from 'sprintf-js';
import thousands from 'thousands';
import { withRouter } from 'react-router';
import { receiveApi } from 'Helpers/helpers';
import Top from 'Components/top';
import Button from 'Components/button';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import * as cons from 'Redux/constants';
import { getReportDetails, getReportDetailsAsync, getReportExport } from 'Redux/actions';
import styles from './PageReportView.less';

class PageReportAsyncView extends PureComponent {
	constructor() {
		super();
		this.isLoading = this.isLoading.bind(this);
		this.getCurrentJobId = this.getCurrentJobId.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onClickPrevHandler = this.onClickPrevHandler.bind(this);
		this.onClickExportHandler = this.onClickExportHandler.bind(this);
		this.onClickDownloadHandler = this.onClickDownloadHandler.bind(this);
		this.onClickPrintHandler = this.onClickPrintHandler.bind(this);
	}

	componentDidMount() {
		this.requestReport();
	}

	componentDidUpdate(prevProps) {
		const search = this.props.location.search;
		const prevSearch = prevProps.location.search;
		const reportJobInfo = this.props.reportJobInfo;
		const prevReportJobInfo = prevProps.reportJobInfo;
		const reportDetailsInfo = this.props.reportDetailsInfo;
		const prevReportDetailsInfo = prevProps.reportDetailsInfo;
		const reportExportInfo = this.props.reportExportInfo;
		const prevReportExportInfo = prevProps.reportExportInfo;

		if (search && search !== prevSearch) {
			this.requestReport();
		}

		if (receiveApi(reportJobInfo, prevReportJobInfo, cons.GET_REPORT_DETAILS_ASYNC)) {
			this.fetchData();
		}

		if (receiveApi(reportDetailsInfo, prevReportDetailsInfo, cons.GET_REPORT_DETAILS)) {
			if (reportDetailsInfo.data && reportDetailsInfo.data.status) {
				switch (reportDetailsInfo.data.status) {
				case 'pending':
				case 'running':
					setTimeout(this.fetchData, 1000);
					break;
				case 'success':
					if (!reportDetailsInfo.data.can_view && reportDetailsInfo.data.can_print) {
						// Handle PDF-only reports
						const id = this.getCurrentJobId();
						this.props.getReportExport({
							fileType: 'embed',
							payload: {
								async: true,
								job_id: id,
							},
						});
					}
					break;
				case 'failed':
					break;
				}
			}
		}

		if (receiveApi(reportExportInfo, prevReportExportInfo, cons.GET_REPORT_EXPORT)) {
			switch (reportExportInfo.data.type) {
			case 'xlsx': {
				const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${reportExportInfo.data.data}`;
				const downloadLink = document.createElement('a');
				const fileName = reportExportInfo.data.filename;

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			}
				break;
			case 'pdf':
				printJS({
					printable: reportExportInfo.data.data,
					type: 'pdf',
					base64: true,
				});
				break;
			}
		}
	}

	isLoading() {
		const { reportJobInfo, reportDetailsInfo, reportExportInfo } = this.props;
		return (
			reportJobInfo.isFetching ||
			reportDetailsInfo.isFetching ||
			(
				reportDetailsInfo.data &&
				reportDetailsInfo.data.status &&
				(reportDetailsInfo.data.status === 'pending' || reportDetailsInfo.data.status === 'running')
			) ||
			reportExportInfo.isFetching
		);
	}

	getCurrentJobId() {
		const { reportJobInfo } = this.props;
		if (reportJobInfo && reportJobInfo.data && reportJobInfo.data.job_id) {
			return reportJobInfo.data.job_id;
		}
		return null;
	}

	requestReport() {
		const param = queryString.parse(this.props.location.search);
		this.props.getReportDetailsAsync({ payload: param });
	}

	fetchData() {
		const id = this.getCurrentJobId();
		if (id) {
			this.props.getReportDetails({
				payload: {
					async: true,
					job_id: id,
				},
			});
		}
	}

	onClickPrevHandler() {
		const { history } = this.props;
		history.push('/app/report');
	}

	onClickExportHandler() {
		let param = null;
		const id = this.getCurrentJobId();
		if (id) {
			param = {
				async: true,
				job_id: id,
			};
		} else {
			param = queryString.parse(this.props.location.search);
		}
		this.props.getReportExport({
			fileType: 'xlsx',
			payload: param,
		});
	}

	onClickDownloadHandler() {
		const { reportExportInfo } = this.props;
		if (reportExportInfo && reportExportInfo.data && reportExportInfo.data.data) {
			const linkSource = `data:application/pdf;base64,${reportExportInfo.data.data}`;
			const downloadLink = document.createElement('a');
			const param = queryString.parse(this.props.location.search);
			const fileName = `ShopMS Report - ${param.date}.pdf`;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	}

	onClickPrintHandler() {
		let param = null;
		const id = this.getCurrentJobId();
		if (id) {
			param = {
				async: true,
				job_id: id,
			};
		} else {
			param = queryString.parse(this.props.location.search);
		}
		param.print = true;
		this.props.getReportExport({
			fileType: 'pdf',
			payload: param,
		});
	}

	render() {
		const { reportJobInfo, reportDetailsInfo, reportExportInfo } = this.props;
		const isLoading = this.isLoading();
		const success = reportDetailsInfo && !reportDetailsInfo.isFetching && reportDetailsInfo.type === cons.GET_REPORT_DETAILS.SUCCESS;
		let totalRow = [];
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div className="uk-flex uk-flex-column">
						<Top
							name="report"
							desc={ (reportJobInfo && reportJobInfo.data) ? reportJobInfo.data.title : undefined }
							onClickPrev={ this.onClickPrevHandler }
						>
							<div className="uk-position-absolute uk-position-top-right uk-margin-small-top">
								{
									success && reportDetailsInfo.data.can_view && reportDetailsInfo.data.can_export && (
										<Button
											text={ loc.export }
											theme="primary"
											disabled={ isLoading || reportExportInfo.isFetching }
											onClick={ this.onClickExportHandler }
										/>
									)
								}
								{
									success && !reportDetailsInfo.data.can_view && reportDetailsInfo.data.can_print && (
										<Button
											text={ loc.download }
											theme="primary"
											disabled={ isLoading }
											onClick={ this.onClickDownloadHandler }
										/>
									)
								}
							</div>
						</Top>

						<div
							id="report-container"
							className={ classnames('uk-height-large', 'uk-overflow-auto') }
							data-uk-height-viewport="offset-top: true; offset-bottom: 70px"
						>
							{
								reportJobInfo && reportJobInfo.data && reportJobInfo.data.params && (
									<ul className="uk-list">
										{
											reportJobInfo.data.params.map((param, index) => (
												<li key={ `page-report-view-param-${index}` }>
													<strong>{ param.name }:</strong> { param.value }
												</li>
											))
										}
									</ul>
								)
							}

							{
								success && (
									<Fragment>
										{
											reportDetailsInfo.data && reportDetailsInfo.data.status === 'success' && reportDetailsInfo.data.can_view ? (
												<div>
													<table className={ classnames('uk-table uk-table-divider uk-table-small', styles.reportDetailsTable) }>
														<thead>
															<tr>
																{
																	reportDetailsInfo.data.headers && reportDetailsInfo.data.headers.map((header, index) => (
																		<th key={ `page-report-view-header-${index}` } className="uk-text-nowrap">{ header }</th>
																	))
																}
															</tr>
														</thead>
														<tbody>
															{
																reportDetailsInfo.data.data.map((row, i) => (
																	<tr key={ `page-report-view-row-${i}` } >
																		{
																			row.map((col, j) => {
																				let colSpan = undefined;
																				let className = null;
																				let isSubtotal = false;
																				if (reportDetailsInfo.data.exclude_in_total && reportDetailsInfo.data.exclude_in_total.includes(i)) {
																					isSubtotal = true;

																					if (reportDetailsInfo.data.type == 10 || reportDetailsInfo.data.type == 11) { // RW0126 & RW0126A
																						if (row[0] && (row[0].includes('BRAND CODE') || row[0].includes('SHOP CODE') || row[0].includes('品牌编号:'))) {
																							if (j === 0) {
																								colSpan = reportDetailsInfo.data.headers.length;
																								className = styles.sectionTitle;
																							} else {
																								return null;
																							}
																						}
																					}
																				} else {
																					if (
																						typeof col === 'number' &&
																						reportDetailsInfo.data.headers[j].indexOf('%') === -1 &&
																						reportDetailsInfo.data.headers[j].indexOf('Brand') === -1 &&
																						reportDetailsInfo.data.headers[j].indexOf('Name') === -1
																					) {
																						if (!totalRow[j]) {
																							totalRow[j] = 0;
																						}
																						totalRow[j] += col;
																					} else if (!totalRow[j]) {
																						totalRow[j] = null;
																					}
																				}

																				return (
																					<td key={ `page-report-view-row-${i}-${j}` } className={ isSubtotal ? classnames('uk-text-bold', 'uk-text-italic', className) : '' } colSpan={ colSpan }>
																						{ typeof col === 'number' ? thousands(col) : col }
																					</td>
																				);
																			})
																		}
																	</tr>
																))
															}
															<tr>
																{
																	totalRow.map((col, index) => (
																		<td key={ `page-report-view-row-total-${index}` } >
																			{
																				typeof col === 'number' ? (
																					<strong>
																						{ (col % 1) ? thousands(sprintf('%.2f', col)) : thousands(col) }
																					</strong>
																				) : ''
																			}
																		</td>
																	))
																}
															</tr>
														</tbody>
													</table>

													<p className="uk-text-center uk-text-italic">** { loc.endOfReport } **</p>
												</div>
											) : (
												reportDetailsInfo.data.can_print && (
													<div className={ styles.pdfWrapper }>
														{
															reportExportInfo && reportExportInfo.data && reportExportInfo.data.data && (
																<iframe
																	src={ `data:application/pdf;base64,${reportExportInfo.data.data}` }
																	className={ styles.pdf }
																/>
															)
														}
													</div>
												)
											)
										}
									</Fragment>
								)
							}
						</div>

						{
							success && reportDetailsInfo.data.can_print && (
								<div className="uk-text-right uk-margin-small-top">
									<Button
										text={ loc.print }
										theme="primary"
										disabled={ isLoading || reportExportInfo.isFetching }
										onClick={ this.onClickPrintHandler }
									/>
								</div>
							)
						}
					</div>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		reportJobInfo: state.reportJobInfo,
		reportDetailsInfo: state.reportDetailsInfo,
		reportExportInfo: state.reportExportInfo,
	}),
	(dispatch) => ({
		getReportDetails: para => dispatch(getReportDetails(para)),
		getReportDetailsAsync: para => dispatch(getReportDetailsAsync(para)),
		getReportExport: para => dispatch(getReportExport(para)),
	})
)(withRouter(PageReportAsyncView));