import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import { getReports } from 'Redux/actions';
import ModalReportFilter from './components/ModalReportFilter.jsx';
import styles from './PageReports.less';

class PageReports extends PureComponent {
	constructor() {
		super();
		this.state = {
			type: null,
			showModalReportFilter: false,
		};
		this.isLoading = this.isLoading.bind(this);
		this.onClickReportHandler = this.onClickReportHandler.bind(this);
		this.onToggleModalReportFilterHandler = this.onToggleModalReportFilterHandler.bind(this);
	}

	componentDidMount() {
		this.props.getReports();
	}

	isLoading() {
		const { reportInfo } = this.props;
		return reportInfo.isFetching;
	}

	onClickReportHandler(type) {
		this.setState({
			type,
			showModalReportFilter: true,
		});
	}

	onToggleModalReportFilterHandler() {
		const { showModalReportFilter } = this.state;
		this.setState({
			showModalReportFilter: !showModalReportFilter,
		});
	}

	render() {
		const { reportInfo } = this.props;
		const { type, showModalReportFilter } = this.state;
		const isLoading = this.isLoading();
		let labelFieldName = 'label';
		switch (loc.Lang) {
		case 'zh-Hant':
			labelFieldName = 'label_tc';
			break;
		case 'zh-Hans':
			labelFieldName = 'label_sc';
			break;
		}
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div className="uk-flex uk-flex-column">
						<Top name="report" />

						<div className="uk-margin-remove-vertical uk-h3 uk-text-primary uk-text-uppercase">
							{ loc.selectReport }
						</div>

						<div className="uk-margin-top uk-grid uk-padding-small">
							{
								reportInfo && reportInfo.data && reportInfo.data.map((report, index) => (
									<div
										key={ `page-report-${index}` }
										className="uk-width-1-1 uk-width-1-2@m"
									>
										<div 
											className={ classnames('uk-card uk-card-default uk-card-body uk-padding-small uk-margin-bottom uk-position-relative', styles.card) }
											onClick={ this.onClickReportHandler.bind(this, report.value) }
										>
											{ report[labelFieldName] }
										</div>
									</div>
								))
							}
						</div>
					</div>
				</LoadingOverlay>

				<ModalReportFilter
					isOpen={ showModalReportFilter }
					onToggle={ this.onToggleModalReportFilterHandler }
					type={ type }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		reportInfo: state.reportInfo,
	}),
	(dispatch) => ({
		getReports: para => dispatch(getReports(para)),
	})
)(PageReports);